function shop() {
    if ($('#cat-menu').length) {
        $('#cat-menu .main-cats a').filter(function() {
            return this.href === location.protocol + '//' + location.host + location.pathname
        }).parent().addClass('active');

        $('#cat-menu .main-cats a').filter(function() {
            return this.href === location.protocol + '//' + location.host + location.pathname
        }).parent().parent().addClass('active');


        $('#cat-menu .main-cats a.sublink').filter(function() {
            return this.href === location.protocol + '//' + location.host + location.pathname
        }).addClass('active');
    }

    window.addEventListener("DOMContentLoaded", function() {
        var currentURL = window.location.pathname; // Aktuelle URL-Pfadangabe
        var currentURL = "/shop/sale/"; // Zielseite

        // console.log(salePageURL);

        if (currentURL === salePageURL) {
            var catsDivs = document.getElementsByClassName("cats");
            for (var i = 0; i < catsDivs.length; i++) {
                catsDivs[i].style.display = "none"; // Verstecken der "cats"-Elemente
            }
        }
    });

    if ($('.cat-toggle').length) {
        $('.cat-toggle').click(function (event) {
            event.preventDefault();
            $(this).toggleClass("open");
            $('.cat-wrapper').toggleClass("open");
        });
    }

}

export {shop};
