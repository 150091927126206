function video() {

    $('.block-video').each(function(){

        $(this).find('.media').each(function(){
            $(this).find('.play-button').on('click', function(){
                $(this).fadeOut();
                $(this).siblings(".iframe")[0].src += "&autoplay=1";
            });
        });

    });

} export { video };
