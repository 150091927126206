import owlCarousel from "owl.carousel";

function teaserslider() {
  $(".teaser-slider").owlCarousel({
    rewind: true,
    margin: 24,
    lazyLoad: true,
    lazyLoadEager: 2,
    autoplay: true,
    autoplaySpeed: 1500,
    autoplayTimeout: 8000,
    nav: false,
    responsive: {
      0: {
        items: 1,
        dots: true,
        autoplay: true,
      },

      576: {
        items: 2,
      },

      1200: {
        items: 3,
        touchDrag: true,
        mouseDrag: true,
        autoplay: false,
      },

      1520: {
        items: 4,
        nav: false,
        dots: false,
        touchDrag: false,
        mouseDrag: false,
      },
    },
  });

  $(window).on('resize', function(){
    $('.teaser-slider').trigger('refresh.owl.carousel');
  });

}

export { teaserslider };
