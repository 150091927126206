import "@fancyapps/fancybox";

function gallery() {
$('.lightbox').fancybox({
  buttons: [
    "close"
  ],
  caption : function( instance, item ) {
    return $(this).data('caption');
  },
  title : function( instance, item ) {
    return $(this).attr('title');
  },
  loop: true,
  protect: true
});

}

export { gallery };
