/* General + Third-Party */
import jQuery from 'jquery';
import { lazyload } from './globals/lazyload';

/* Layout-Parts */
// import { usps } from './layout/footer';
import { header } from './layout/header';

/* Blocks */
import { teaserslider } from '../../blocks/teaser/script';
import { gallery } from '../../blocks/gallery/script';
import { product_teaser } from '../../blocks/product-teaser/script';
import { logo_slider } from '../../blocks/logoleiste/script';
import { checkInput } from '../../blocks/form/script';
import { video } from '../../blocks/video/script';

/* Woocommerce */
import { shop } from './woocommerce/shop';


jQuery(document).ready(_ => {

  // General + Third-Party
  lazyload();

  //Layout-Parts
  // usps();
  header();

  //Blocks
  teaserslider();
  gallery();
  product_teaser();
  logo_slider();
  checkInput();
  video();

  //Woocommerce
  shop();


  //ANIMATION
  // if ($(window).width() > 992) {
    $(window).scroll(function () {
      inViewport();
    });

    $(window).resize(function () {
      inViewport();
    });

    function inViewport() {
      $(".animation").each(function () {
        var divPos = $(this).offset().top,
          topOfWindow = $(window).scrollTop();

        if (divPos < topOfWindow + 900) {
          $(this).addClass("in-view");
        }
        // else {
        //   $(this).removeClass("in-view");
        // }
      });
    }
  // }

});
