function header() {
  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('header, nav, .backdrop').toggleClass("open");
    $('html, body, main').toggleClass("hidden");
  });

  $('.action-search').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("hidden");
    $('.search-slide').toggleClass("active");
    $('header').toggleClass("search-open");
  });

  $('li.children button').click(function (event) {
    event.preventDefault();
    $('li.children').not($(this).parent('li.children')).removeClass('show');
    $(this).parent('li.children').toggleClass("show");

  });

  // $('.action-search').click(function (event) {
  //   event.preventDefault();
  //   $(this).toggleClass("hidden");
  //   $('.search-slide').toggleClass("active");
  //   $('header').toggleClass("search-open");
  // });


  //ADD CLASS BY SCROLLING

  $(window).scroll(function(){
    var st = $(window).scrollTop();
    if ( st > 0 ) {
      $('header').addClass('fixed');
      $('.head-banner').addClass('fixed');
    } else {
      $('header').removeClass('fixed');
      $('.head-banner').removeClass('fixed');
    }
  });

  var st = $(window).scrollTop();
  if ( st > 0 ) {
    $('header').addClass('fixed');
    $('.head-banner').addClass('fixed');
  } else {
    $('header').removeClass('fixed');
    $('.head-banner').removeClass('fixed');
  }
}
export { header };
