import owlCarousel from "owl.carousel";
function logo_slider() {

    var $owl = $('.logo-slider');

    $owl.owlCarousel({
        loop: true,
        items: 2,
        dots: false,
        nav: false,
        autoplaySpeed: 1500,
        lazyLoad: true,
        margin: 40,
        autoplay: true,

         responsive:{
            576:{
                items: 3,
            },
            1200:{
                items: 5,
            },
        }
    });

    $(window).on('resize', function(){
        setTimeout(function(){
            $('.slider').trigger('refresh.owl.carousel');
        }, 500);
    });
}

export {logo_slider };
