function checkInput() {
    // check input value
    // $("body").on("change", "input, textarea", function () {
    $("body").on("change", "input", function () {

        if ($(this).val().length > 0) {
            $(this).removeClass("error");
        }

        if ($(this).attr("type") == "checkbox") {
            const inputname = $(this).attr("name");
            $("input[name=" + inputname + "]").removeClass("error");
        }
    });
}
export { checkInput };
